@import url(https://fonts.googleapis.com/css?family=Amatic+SC);
@import url(https://fonts.googleapis.com/css?family=Seaweed+Script);
@import url(https://fonts.googleapis.com/css?family=Leckerli+One);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab);
@import url(https://fonts.googleapis.com/css?family=Vesper+Libre);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Kalam);
@import url(https://fonts.googleapis.com/css?family=Oswald);
/* font-family: 'Amatic SC', cursive; */
/* font-family: 'Seaweed Script', cursive; */
/* font-family: 'Leckerli One', cursive; */
/* font-family: 'Playfair Display', serif; */
/* font-family: 'Roboto Slab', serif; */
/* font-family: 'Open Sans', sans-serif; */


html {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 10px; }

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden; }

* {
  margin: 0;
  padding: 0; }

ul {
  list-style: none; }

a {
  text-decoration: none;
  color: #393939; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
     -moz-box-sizing: inherit;
          box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  line-height: 2;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.wrapper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 1000; }

.rose-hr-box {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .rose-hr-box .rose-hr {
    width: auto;
    height: 20px; }

button,
button:focus,
a,
a:focus,
a:link,
a:active,
a:visited {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  outline: 0;
  border: 0;
  cursor: pointer; }

p {
  font-size: 16px;
  line-height: 1.5; }

.h1-heading-center {
  margin: 0 auto;
  text-align: center;
  line-height: 1;
  color: #232d3e;
  font-family: "Amatic SC", cursive;
  font-size: 64px;
  font-weight: 700; }
  @media (max-width: 767px) {
    .h1-heading-center {
      font-size: 44px; } }

.mtb-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.ptb-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.ptb-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.fullWidthContainer {
  width: 100%;
  background: url("/images/menu-outer-bg3.jpg");
  background-repeat: repeat;
  padding: 1rem 0 1rem 0; }

.seoulContainer {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
  position: relative; }
  @media (min-width: 576px) {
    .seoulContainer {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .seoulContainer {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .seoulContainer {
      max-width: 960px; } }
  @media (min-width: 1460px) {
    .seoulContainer {
      max-width: 1430px; } }

.seoulContainer-m {
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden; }
  @media (min-width: 576px) {
    .seoulContainer-m {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .seoulContainer-m {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .seoulContainer-m {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .seoulContainer-m {
      max-width: 1140px; } }

.dailySpecialContainer {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
  position: relative; }
  @media (min-width: 576px) {
    .dailySpecialContainer {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .dailySpecialContainer {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .dailySpecialContainer {
      max-width: 960px; } }

.master-footer {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.5))), url("/images/contact-header.jpg");
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("/images/contact-header.jpg");
  background: -moz-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("/images/contact-header.jpg");
  background: -o-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("/images/contact-header.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("/images/contact-header.jpg");
  -moz-background-size: cover;
    -o-background-size: cover;
       background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  width: 100%;
  position: relative;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .master-footer .to-top-box {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: rgba(0, 0, 0, 0.7); }
    .master-footer .to-top-box .to-top {
      padding: 10px 20px;
      bottom: 0;
      right: 0;
      color: #fff;
      cursor: pointer;
      font-size: 20px;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      line-height: 1;
      font-family: "Kalam", cursive; }
  .master-footer .footer-header {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media (max-width: 768px) {
      .master-footer .footer-header {
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 0;
        padding-bottom: 20px; } }
    .master-footer .footer-header .footer-item {
      width: 33.33%;
      margin-top: 30px;
      margin-bottom: 30px;
      color: #fff;
      padding-left: 20px;
      padding-right: 20px; }
      .master-footer .footer-header .footer-item h1 {
        font-family: "Vesper Libre", serif;
        text-align: center; }
      @media (max-width: 768px) {
        .master-footer .footer-header .footer-item {
          width: 80%;
          margin: 0 auto;
          margin-top: 30px;
          padding-left: 20px;
          padding-right: 20px; } }
      @media (max-width: 576px) {
        .master-footer .footer-header .footer-item {
          width: 100%;
          margin: 0 auto;
          margin-top: 30px;
          padding-left: 10px;
          padding-right: 10px; } }
    .master-footer .footer-header .about {
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .master-footer .footer-header .about .heading {
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .master-footer .footer-header .about .heading h1 {
          color: #e1e1e1;
          vertical-align: top;
          line-height: 1; }
        .master-footer .footer-header .about .heading img {
          margin-left: 16px;
          width: auto;
          height: 60px; }
      .master-footer .footer-header .about p {
        width: 90%;
        margin-top: 10px;
        color: #fff;
        text-align: center; }
        .master-footer .footer-header .about p span {
          font-style: italic;
          font-weight: bold; }
      .master-footer .footer-header .about a {
        margin-top: 8px;
        font-family: "Amatic SC", cursive;
        font-size: 24px;
        font-weight: bold;
        border: 1px solid #8b0000;
        -webkit-border-radius: 5px;
           -moz-border-radius: 5px;
                border-radius: 5px;
        padding: 2px 16px;
        background: #8b0000;
        color: white;
        -webkit-transition: all 350ms ease-in-out;
        -o-transition: all 350ms ease-in-out;
        -moz-transition: all 350ms ease-in-out;
        transition: all 350ms ease-in-out; }
        .master-footer .footer-header .about a:hover {
          background: white;
          color: #8b0000; }
    .master-footer .footer-header .hours {
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      @media (max-width: 768px) {
        .master-footer .footer-header .hours.hide {
          display: none; } }
      .master-footer .footer-header .hours hr {
        background: #e1e1e1;
        height: 2px;
        width: 100%;
        max-width: 160px;
        margin: 0 auto; }
      .master-footer .footer-header .hours .time {
        margin: 0 auto;
        margin-top: 16px;
        width: 80%; }
        @media (max-width: 1460px) {
          .master-footer .footer-header .hours .time {
            width: 100%; } }
        @media (max-width: 767px) {
          .master-footer .footer-header .hours .time {
            width: 90%; } }
        @media (max-width: 359px) {
          .master-footer .footer-header .hours .time {
            width: 98%; } }
        .master-footer .footer-header .hours .time div {
          width: 100%;
          display: -webkit-flex;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex; }
          .master-footer .footer-header .hours .time div span:nth-child(1) {
            text-align: left;
            width: 50%; }
          .master-footer .footer-header .hours .time div span:nth-child(2) {
            font-family: "Playfair Display", serif;
            color: #00be00;
            text-align: right;
            width: 50%;
            font-weight: bold; }
          .master-footer .footer-header .hours .time div.closed span:nth-child(2) {
            font-family: "Playfair Display", serif;
            color: #be0000;
            font-weight: bold; }
    .master-footer .footer-header .info {
      margin-bottom: 10px; }
      @media (max-width: 768px) {
        .master-footer .footer-header .info.hide {
          display: none; } }
      .master-footer .footer-header .info .info-items {
        width: 80%;
        margin: 0 auto;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
        .master-footer .footer-header .info .info-items .info-item {
          display: -webkit-flex;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          line-height: 1;
          margin-bottom: 20px; }
          .master-footer .footer-header .info .info-items .info-item .fa-map-marked-alt,
          .master-footer .footer-header .info .info-items .info-item .fa-phone {
            color: #ffa500;
            margin-right: 20px; }
        .master-footer .footer-header .info .info-items .amazingMenu,
        .master-footer .footer-header .info .info-items .moreInfo {
          margin: 0 auto;
          font-family: "Amatic SC", cursive;
          font-size: 24px;
          font-weight: bold;
          border: 1px solid #232d3e;
          background: #232d3e;
          -webkit-border-radius: 5px;
             -moz-border-radius: 5px;
                  border-radius: 5px;
          padding: 2px 16px;
          color: white;
          -webkit-transition: all 350ms ease-in-out;
          -o-transition: all 350ms ease-in-out;
          -moz-transition: all 350ms ease-in-out;
          transition: all 350ms ease-in-out; }
          .master-footer .footer-header .info .info-items .amazingMenu:hover,
          .master-footer .footer-header .info .info-items .moreInfo:hover {
            background: white;
            color: #232d3e; }
  .master-footer .copy-right {
    height: 60px;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #ededed;
    color: #393939; }
    .master-footer .copy-right p {
      text-align: center;
      line-height: 1.3; }
    .master-footer .copy-right .designed-by {
      margin-left: 10px; }
      .master-footer .copy-right .designed-by .family-of-light {
        font-family: "Playfair Display", serif;
        padding: 8px 4px;
        -webkit-border-radius: 2px;
           -moz-border-radius: 2px;
                border-radius: 2px; }
        .master-footer .copy-right .designed-by .family-of-light:hover {
          background: #232d3e;
          color: white;
          -webkit-transition: all 250ms linear;
          -o-transition: all 250ms linear;
          -moz-transition: all 250ms linear;
          transition: all 250ms linear; }
          .master-footer .copy-right .designed-by .family-of-light:hover a {
            background: transparent;
            color: inherit; }

.header {
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  position: relative;
  height: 400px;
  -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
       background-size: 100% 100%; }
  @media (min-height: 1200px) {
    .header {
      height: 600px;
      -moz-background-size: 100% 600px;
        -o-background-size: 100% 600px;
           background-size: 100% 600px; } }
  @media (max-width: 767px) {
    .header {
      height: 260px;
      -moz-background-size: 100% 260px;
        -o-background-size: 100% 260px;
           background-size: 100% 260px; } }
  .header h2 {
    position: absolute;
    font-family: "Amatic SC", cursive;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
       -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
         -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 30px;
    color: #ededed;
    line-height: 1.3;
    word-spacing: 5px;
    text-align: center;
    width: 80%;
    max-width: 640px; }

.menuHeader {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.2))), url("/images/menu/kimchi-backgroundDark.jpg");
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/menu/kimchi-backgroundDark.jpg");
  background: -moz-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/menu/kimchi-backgroundDark.jpg");
  background: -o-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/menu/kimchi-backgroundDark.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/menu/kimchi-backgroundDark.jpg"); }
  @media (min-height: 1200px) {
    .menuHeader {
      -moz-background-size: 100% 600px;
        -o-background-size: 100% 600px;
           background-size: 100% 600px; } }
  @media (max-width: 767px) {
    .menuHeader {
      -moz-background-size: 100% 100%;
        -o-background-size: 100% 100%;
           background-size: 100% 100%; } }

.cateringHeader {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.2))), url("/images/menu/kimchi-backgroundDark.jpg");
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/menu/kimchi-backgroundDark.jpg");
  background: -moz-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/menu/kimchi-backgroundDark.jpg");
  background: -o-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/menu/kimchi-backgroundDark.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/menu/kimchi-backgroundDark.jpg"); }
  @media (min-height: 1200px) {
    .cateringHeader {
      -moz-background-size: 100% 600px;
        -o-background-size: 100% 600px;
           background-size: 100% 600px; } }
  @media (max-width: 767px) {
    .cateringHeader {
      -moz-background-size: 100% 100%;
        -o-background-size: 100% 100%;
           background-size: 100% 100%; } }

.contactHeader {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.2))), url("/images/contact-header.jpg");
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/contact-header.jpg");
  background: -moz-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/contact-header.jpg");
  background: -o-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/contact-header.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/contact-header.jpg");
  background-position: bottom center; }
  @media (min-height: 1200px) {
    .contactHeader {
      -moz-background-size: 100% 600px;
        -o-background-size: 100% 600px;
           background-size: 100% 600px; } }
  @media (max-width: 767px) and (orientation: portrait) {
    .contactHeader {
      -moz-background-size: 100% 260px;
        -o-background-size: 100% 260px;
           background-size: 100% 260px; } }

.landingHeader {
  width: 100%;
  min-height: 100vh;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.2))), url("/images/landing-header-background.jpg");
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/landing-header-background.jpg");
  background: -moz-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/landing-header-background.jpg");
  background: -o-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/landing-header-background.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/images/landing-header-background.jpg");
  background-repeat: no-repeat;
  -moz-background-size: cover;
    -o-background-size: cover;
       background-size: cover;
  background-position: top center;
  position: relative;
  -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
          backface-visibility: hidden; }
  .landingHeader div {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
       -moz-transform: translate(-50%, -50%) scale(0);
        -ms-transform: translate(-50%, -50%) scale(0);
         -o-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    -webkit-transition: opacity 300ms, -webkit-transform 1000ms;
    transition: opacity 300ms, -webkit-transform 1000ms;
    -o-transition: opacity 300ms, -o-transform 1000ms;
    -moz-transition: opacity 300ms, transform 1000ms, -moz-transform 1000ms;
    transition: opacity 300ms, transform 1000ms;
    transition: opacity 300ms, transform 1000ms, -webkit-transform 1000ms, -moz-transform 1000ms, -o-transform 1000ms;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    opacity: 0;
    -webkit-backface-visibility: hidden;
       -moz-backface-visibility: hidden;
            backface-visibility: hidden; }
    .landingHeader div.animate {
      opacity: 1;
      -webkit-transform: translate(-50%, -50%) scale(1);
         -moz-transform: translate(-50%, -50%) scale(1);
          -ms-transform: translate(-50%, -50%) scale(1);
           -o-transform: translate(-50%, -50%) scale(1);
              transform: translate(-50%, -50%) scale(1); }
    .landingHeader div * {
      text-align: center; }
    .landingHeader div h1 {
      font-family: "Amatic SC", cursive;
      font-size: 64px;
      line-height: 1;
      color: #fff;
      max-width: 840px; }
    .landingHeader div h2 {
      font-family: "Amatic SC", cursive;
      font-size: 50px;
      color: #fff;
      max-width: 740px; }
    .landingHeader div h4 {
      margin-top: 20px;
      font-family: "Amatic SC", cursive;
      color: #fff;
      font-size: 24px;
      line-height: 1.4;
      max-width: 640px; }
    .landingHeader div a {
      margin-top: 20px;
      padding: 10px 30px;
      background: #dc3522;
      color: #f2f2f2;
      border: 1px solid #dc3522;
      -webkit-border-radius: 30px;
         -moz-border-radius: 30px;
              border-radius: 30px;
      outline: none;
      -webkit-transition: background 300ms linear, color 300ms linear, opacity 500ms;
      -o-transition: background 300ms linear, color 300ms linear, opacity 500ms;
      -moz-transition: background 300ms linear, color 300ms linear, opacity 500ms;
      transition: background 300ms linear, color 300ms linear, opacity 500ms;
      opacity: 0; }
      .landingHeader div a:hover {
        background: #ededed;
        color: #dc3522; }
      .landingHeader div a.animate {
        opacity: 1; }
    @media (max-width: 767px) {
      .landingHeader div h1 {
        font-size: 48px;
        line-height: 1; }
      .landingHeader div h4 {
        line-height: 1.2; } }

.menu-container {
  background: #fff;
  overflow: hidden;
  padding: 10px 0; }
  @media (min-width: 767px) {
    .menu-container {
      -webkit-border-radius: 5px;
         -moz-border-radius: 5px;
              border-radius: 5px; } }
  @media (max-width: 359px) {
    .menu-container {
      padding: 5px 0; } }
  .menu-container .menuRouteAnimation {
    position: relative; }
    .menu-container .menuRouteAnimation .fade {
      opacity: 0;
      z-index: 1; }
    .menu-container .menuRouteAnimation .fade-enter {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      opacity: 1;
      -webkit-transition: opacity 300ms linear 150ms;
      -o-transition: opacity 300ms linear 150ms;
      -moz-transition: opacity 300ms linear 150ms;
      transition: opacity 300ms linear 150ms; }
    .menu-container .menuRouteAnimation .fade-enter.fade-enter-active {
      opacity: 1; }
    .menu-container .menuRouteAnimation .fade-exit {
      opacity: 1; }
    .menu-container .menuRouteAnimation .fade-exit.fade-exit-active {
      opacity: 0;
      -webkit-transition: opacity 150ms linear;
      -o-transition: opacity 150ms linear;
      -moz-transition: opacity 150ms linear;
      transition: opacity 150ms linear; }
  .menu-container .menuContentBox {
    -webkit-backface-visibility: hidden;
       -moz-backface-visibility: hidden;
            backface-visibility: hidden;
    position: relative; }
    .menu-container .menuContentBox h1 {
      color: #232d3e;
      text-align: center;
      font-family: "Amatic SC", cursive;
      font-size: 64px;
      font-weight: 700;
      line-height: 1.4; }
      @media (max-width: 767px) {
        .menu-container .menuContentBox h1 {
          font-size: 44px; } }
    .menu-container .menuContentBox .cashOnly {
      font-size: 12px;
      position: absolute;
      top: -0.2%;
      right: 1%;
      font-style: italic;
      color: #6389c1;
      line-height: 0.8; }
    .menu-container .menuContentBox .titleDesc {
      text-align: center;
      font-style: italic;
      color: #35455f;
      font-weight: bold;
      line-height: 1.2;
      margin-left: 10px;
      margin-right: 10px; }
    .menu-container .menuContentBox .itemsBox {
      margin-top: 15px;
      padding: 20px 100px; }
      @media (max-width: 1023px) {
        .menu-container .menuContentBox .itemsBox {
          padding: 40px; } }
      @media (max-width: 767px) {
        .menu-container .menuContentBox .itemsBox {
          padding: 10px; } }
      @media (max-width: 359px) {
        .menu-container .menuContentBox .itemsBox {
          padding: 6px; } }
      .menu-container .menuContentBox .itemsBox .itemBoxGrid {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr; }
        @media (max-width: 767px) {
          .menu-container .menuContentBox .itemsBox .itemBoxGrid {
            grid-gap: 10px;
            grid-template-columns: 1fr; } }
        .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox {
          display: -webkit-flex;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-justify-content: center;
             -moz-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center; }
          .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox:hover .imageBox a img {
            border: 4px solid #ffb733; }
          .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox {
            overflow: hidden;
            width: 160px;
            height: 160px;
            -webkit-backface-visibility: hidden;
               -moz-backface-visibility: hidden;
                    backface-visibility: hidden;
            position: relative; }
            .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box {
              display: -webkit-flex;
              display: -moz-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-justify-content: center;
                 -moz-box-pack: center;
                  -ms-flex-pack: center;
                      justify-content: center;
              -webkit-align-items: center;
                 -moz-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              position: fixed;
              background: rgba(0, 0, 0, 0.8);
              z-index: 200;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              right: 0;
              -webkit-transition: -webkit-transform 1000ms;
              transition: -webkit-transform 1000ms;
              -o-transition: -o-transform 1000ms;
              -moz-transition: transform 1000ms, -moz-transform 1000ms;
              transition: transform 1000ms;
              transition: transform 1000ms, -webkit-transform 1000ms, -moz-transform 1000ms, -o-transform 1000ms; }
              .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox {
                -webkit-border-radius: 40px;
                   -moz-border-radius: 40px;
                        border-radius: 40px;
                background: #fff;
                width: 100%;
                max-width: 820px;
                margin-left: 0;
                padding: 10px;
                display: -webkit-flex;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-flex-direction: column;
                   -moz-box-orient: vertical;
                   -moz-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                -webkit-justify-content: center;
                   -moz-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                -webkit-align-items: center;
                   -moz-box-align: center;
                    -ms-flex-align: center;
                        align-items: center; }
                @media (max-width: 900px) and (orientation: landscape) {
                  .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox {
                    max-width: 390px;
                    -webkit-border-radius: 0;
                       -moz-border-radius: 0;
                            border-radius: 0;
                    margin-left: 5px;
                    margin-right: 5px; } }
                @media (max-width: 666px) and (orientation: landscape) {
                  .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox {
                    max-width: 280px; } }
                @media (max-width: 900px) and (orientation: portrait) {
                  .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox {
                    -webkit-border-radius: 0;
                       -moz-border-radius: 0;
                            border-radius: 0; } }
                .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .title {
                  color: #393939;
                  line-height: 1;
                  margin-bottom: 10px; }
                  @media (max-width: 900px) and (orientation: landscape) {
                    .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .title {
                      font-size: 34px;
                      margin-bottom: 5px; } }
                .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .titleDesc {
                  color: #6389c1;
                  line-height: 1;
                  font-size: 12px; }
                  @media (orientation: landscape) {
                    .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .titleDesc {
                      margin-top: 10px; } }
                .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .img-box {
                  -webkit-backface-visibility: hidden;
                     -moz-backface-visibility: hidden;
                          backface-visibility: hidden;
                  overflow: hidden; }
                  .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .img-box img {
                    -webkit-backface-visibility: hidden;
                       -moz-backface-visibility: hidden;
                            backface-visibility: hidden;
                    width: 100%;
                    height: auto;
                    -webkit-border-radius: 40px;
                       -moz-border-radius: 40px;
                            border-radius: 40px;
                    max-width: 800px; }
                    @media (max-width: 900px) and (orientation: landscape) {
                      .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .img-box img {
                        max-width: 370px; } }
                    @media (max-width: 666px) and (orientation: landscape) {
                      .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .img-box img {
                        max-width: 260px; } }
                .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .desc {
                  color: #393939;
                  text-align: center;
                  font-size: 12px; }
                  @media (max-width: 900px) and (orientation: landscape) {
                    .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .desc {
                      margin-top: -10px; } }
                .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .priceBox {
                  line-height: 1; }
                  .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .priceBox .priceDollar,
                  .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .priceBox .price {
                    font-size: 16px;
                    color: #d80000; }
                  .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .priceBox .priceDollar {
                    font-family: "Roboto Slab", serif; }
                  .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .contentBox .priceBox .price {
                    font-family: "Playfair Display", serif; }
              .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox .popup-box .close {
                position: absolute;
                right: 2%;
                top: 2%;
                padding: 0;
                margin: 0;
                outline: 0;
                width: 60px;
                height: 60px;
                border: 2px solid #ededed;
                -webkit-border-radius: 50%;
                   -moz-border-radius: 50%;
                        border-radius: 50%;
                display: -webkit-flex;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-justify-content: center;
                   -moz-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                -webkit-align-items: center;
                   -moz-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                cursor: pointer;
                font-size: 30px;
                color: #ededed; }
            @media (max-width: 1023px) {
              .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox {
                width: 140px;
                height: 140px; }
                .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox img {
                  -webkit-border-radius: 70px;
                     -moz-border-radius: 70px;
                          border-radius: 70px; } }
            .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .imageBox img {
              border: 4px solid rgba(0, 0, 0, 0);
              -webkit-transform: translateZ(0);
                 -moz-transform: translateZ(0);
                      transform: translateZ(0);
              -webkit-perspective: 1000;
                 -moz-perspective: 1000;
                      perspective: 1000;
              -webkit-backface-visibility: hidden;
                 -moz-backface-visibility: hidden;
                      backface-visibility: hidden;
              -webkit-border-radius: 80px;
                 -moz-border-radius: 80px;
                      border-radius: 80px;
              width: 100%;
              height: 100%;
              -webkit-user-select: none;
                 -moz-user-select: none;
                  -ms-user-select: none;
                      user-select: none;
              -webkit-filter: brightness(110%);
                      filter: brightness(110%);
              -webkit-transition: border 200ms ease-in-out;
              -o-transition: border 200ms ease-in-out;
              -moz-transition: border 200ms ease-in-out;
              transition: border 200ms ease-in-out; }
          .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .contentBox {
            -webkit-flex: 1 1;
               -moz-box-flex: 1;
                -ms-flex: 1 1;
                    flex: 1 1;
            margin-left: 20px;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-justify-content: center;
               -moz-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center; }
            @media (max-width: 767px) {
              .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .contentBox {
                margin-left: 10px; } }
            .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .contentBox .name {
              position: relative;
              font-family: "Roboto Slab", serif;
              color: #393939;
              font-weight: bold;
              font-size: 18px;
              line-height: 1.2;
              margin-bottom: 5px; }
              .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .contentBox .name .nameDescription {
                position: absolute;
                font-size: 10px;
                top: 40%;
                margin-left: 4px;
                color: #6389c1; }
                @media (max-width: 359px) {
                  .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .contentBox .name .nameDescription {
                    font-size: 9px;
                    margin-left: 2px;
                    top: 40%;
                    line-height: 1; } }
              .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .contentBox .name .day {
                position: absolute;
                font-size: 12px;
                font-style: italic;
                margin-top: -18px;
                top: 0;
                left: 10%;
                padding: 1px 6px;
                -webkit-border-radius: 9px;
                   -moz-border-radius: 9px;
                        border-radius: 9px;
                background: #008b00;
                color: #ededed; }
            .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .contentBox .price {
              font-size: 18px;
              font-weight: bold;
              font-family: "Playfair Display", serif;
              color: #a50000; }
              .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .contentBox .price .striked {
                font-family: "Roboto Slab", serif;
                color: #8b0000;
                text-decoration: line-through; }
              .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .contentBox .price .dollar-sign {
                font-family: "Roboto Slab", serif;
                margin-right: 2px; }
            .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .contentBox .description {
              color: #3a3a3a;
              font-style: italic;
              line-height: 1.2; }
            .menu-container .menuContentBox .itemsBox .itemBoxGrid .itemBox .contentBox .description2 {
              color: #485c7f;
              font-weight: bold;
              line-height: 1.2;
              margin-bottom: 4px; }

.menuNav {
  z-index: 1;
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  -webkit-transition: all 300ms;
  -o-transition: all 300ms;
  -moz-transition: all 300ms;
  transition: all 300ms;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 52px; }
  .menuNav.fixed {
    position: fixed;
    background: #ffb733; }
  .menuNav * {
    overflow: hidden; }
  .menuNav .slider-wrapper {
    z-index: 2;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 48px; }
    .menuNav .slider-wrapper .slider-inner {
      z-index: 4;
      background: #e1e1e1;
      position: relative;
      overflow-x: scroll;
      height: 70px;
      width: 680px; }
      .menuNav .slider-wrapper .slider-inner ul.slider {
        z-index: 5;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-justify-content: 'flex-start';
           -moz-box-pack: 'flex-start';
            -ms-flex-pack: 'flex-start';
                justify-content: 'flex-start';
        -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
        position: absolute;
        height: 70px;
        /* top: -11px; */
        top: -11px; }
        .menuNav .slider-wrapper .slider-inner ul.slider.center {
          left: 50%;
          -webkit-transform: translateX(-50%);
             -moz-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
               -o-transform: translateX(-50%);
                  transform: translateX(-50%); }
        .menuNav .slider-wrapper .slider-inner ul.slider li {
          margin-left: 2px;
          -webkit-border-radius: 20px;
             -moz-border-radius: 20px;
                  border-radius: 20px;
          height: 40px;
          padding: 2px 0;
          display: -webkit-flex;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-justify-content: center;
             -moz-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-align-items: center;
             -moz-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          .menuNav .slider-wrapper .slider-inner ul.slider li:last-child {
            margin-right: 2px; }
          .menuNav .slider-wrapper .slider-inner ul.slider li a {
            width: 100%;
            height: 100%;
            font-family: 'Amatic SC', cursive;
            font-size: 20px;
            font-weight: 700;
            padding: 0 14px;
            height: 40px;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
               -moz-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-border-radius: inherit;
               -moz-border-radius: inherit;
                    border-radius: inherit;
            -webkit-transition: all 300ms ease-in-out;
            -o-transition: all 300ms ease-in-out;
            -moz-transition: all 300ms ease-in-out;
            transition: all 300ms ease-in-out;
            white-space: nowrap;
            color: #8b0000; }
            .menuNav .slider-wrapper .slider-inner ul.slider li a:hover {
              color: #393939; }
            .menuNav .slider-wrapper .slider-inner ul.slider li a.menu-nav-active {
              /* background: ${props => props.theme.lightRed}; */
              background: #8b0000;
              color: #ededed; }

.mainNav {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  -webkit-transition: background 300ms;
  -o-transition: background 300ms;
  -moz-transition: background 300ms;
  transition: background 300ms; }
  .mainNav.fixed {
    background: #ffb733; }
    .mainNav.fixed a {
      color: #ededed; }
  .mainNav ul {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .mainNav ul li {
      padding: 0.5rem 1rem;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .mainNav ul li:first-child {
        margin-right: 1rem; }
      .mainNav ul li a {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
        height: 60px;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-transition: color 300ms ease-in-out;
        -o-transition: color 300ms ease-in-out;
        -moz-transition: color 300ms ease-in-out;
        transition: color 300ms ease-in-out;
        color: #f2f2f2; }
        .mainNav ul li a.active {
          color: #8b0000; }
          .mainNav ul li a.active:hover {
            color: #8b0000; }
        .mainNav ul li a:hover {
          color: #393939; }
        .mainNav ul li a div {
          display: -webkit-flex;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-align-items: flex-end;
             -moz-box-align: end;
              -ms-flex-align: end;
                  align-items: flex-end; }
          .mainNav ul li a div > *:nth-child(2) {
            margin-left: 6px; }
          .mainNav ul li a div img {
            width: auto;
            -o-object-fit: cover;
               object-fit: cover; }
          .mainNav ul li a div img:nth-child(1) {
            height: 60px; }
          .mainNav ul li a div img:nth-child(2) {
            height: 38px; }
  .mainNav.mobileNav.fixed {
    background: #ffb733 !important; }
  .mainNav.mobileNav .seoulContainer {
    position: relative; }
    .mainNav.mobileNav .seoulContainer ul {
      -webkit-justify-content: flex-start;
         -moz-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
      .mainNav.mobileNav .seoulContainer ul li a div > *:nth-child(2) {
        margin-left: 4px; }
      .mainNav.mobileNav .seoulContainer ul li a div img:nth-child(1) {
        height: 50px; }
      .mainNav.mobileNav .seoulContainer ul li a div img:nth-child(2) {
        height: 30px; }
    .mainNav.mobileNav .seoulContainer .mobile-nav-button {
      position: absolute;
      top: 50%;
      right: 4%;
      -webkit-transform: translateY(-50%);
         -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
           -o-transform: translateY(-50%);
              transform: translateY(-50%);
      background: none; }
      .mainNav.mobileNav .seoulContainer .mobile-nav-button .bar1,
      .mainNav.mobileNav .seoulContainer .mobile-nav-button .bar2,
      .mainNav.mobileNav .seoulContainer .mobile-nav-button .bar3 {
        width: 35px;
        height: 3px;
        background: #f2f2f2;
        margin: 6px 0;
        -webkit-transition: 400ms;
        -o-transition: 400ms;
        -moz-transition: 400ms;
        transition: 400ms; }
      .mainNav.mobileNav .seoulContainer .mobile-nav-button.change .bar1 {
        -webkit-transform: rotate(-45deg) translate(-8px, 4px);
           -moz-transform: rotate(-45deg) translate(-8px, 4px);
            -ms-transform: rotate(-45deg) translate(-8px, 4px);
             -o-transform: rotate(-45deg) translate(-8px, 4px);
                transform: rotate(-45deg) translate(-8px, 4px); }
      .mainNav.mobileNav .seoulContainer .mobile-nav-button.change .bar2 {
        opacity: 0; }
      .mainNav.mobileNav .seoulContainer .mobile-nav-button.change .bar3 {
        -webkit-transform: rotate(45deg) translate(-8px, -5px);
           -moz-transform: rotate(45deg) translate(-8px, -5px);
            -ms-transform: rotate(45deg) translate(-8px, -5px);
             -o-transform: rotate(45deg) translate(-8px, -5px);
                transform: rotate(45deg) translate(-8px, -5px); }

.pop-up {
  position: fixed;
  background: #ffb733;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  -webkit-transform: scale(0);
     -moz-transform: scale(0);
      -ms-transform: scale(0);
       -o-transform: scale(0);
          transform: scale(0);
  -webkit-transition: opacity 1000ms ease, -webkit-transform 250ms ease;
  transition: opacity 1000ms ease, -webkit-transform 250ms ease;
  -o-transition: opacity 1000ms ease, -o-transform 250ms ease;
  -moz-transition: transform 250ms ease, opacity 1000ms ease, -moz-transform 250ms ease;
  transition: transform 250ms ease, opacity 1000ms ease;
  transition: transform 250ms ease, opacity 1000ms ease, -webkit-transform 250ms ease, -moz-transform 250ms ease, -o-transform 250ms ease; }
  .pop-up.show {
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
        -ms-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1); }
    .pop-up.show a {
      opacity: 1; }
  .pop-up ul li {
    padding: 10px 15px; }
    .pop-up ul li a {
      color: #f2f2f2;
      font-size: 20px;
      text-transform: uppercase;
      opacity: 0;
      font-weight: bold; }
      .pop-up ul li a.active {
        color: #8b0000; }

.loading-spin {
  -webkit-animation: App-logo-spin infinite 1s linear;
     -moz-animation: App-logo-spin infinite 1s linear;
       -o-animation: App-logo-spin infinite 1s linear;
          animation: App-logo-spin infinite 1s linear;
  z-index: 1001;
  font-size: 32px;
  color: #333;
  position: fixed;
  top: 1%;
  left: 92%; }
  @media (max-width: 767px) {
    .loading-spin {
      left: 70%;
      -webkit-transform: translateX(-70%);
         -moz-transform: translateX(-70%);
          -ms-transform: translateX(-70%);
           -o-transform: translateX(-70%);
              transform: translateX(-70%); } }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-moz-keyframes App-logo-spin {
  from {
    -moz-transform: rotate(0deg);
         transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg);
         transform: rotate(360deg); } }

@-o-keyframes App-logo-spin {
  from {
    -o-transform: rotate(0deg);
       transform: rotate(0deg); }
  to {
    -o-transform: rotate(360deg);
       transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
         -o-transform: rotate(360deg);
            transform: rotate(360deg); } }

@charset "UTF-8";
.prettyHr {
  margin: 0 auto;
  display: block;
  unicode-bidi: -webkit-isolate;
  unicode-bidi: -moz-isolate;
  unicode-bidi: isolate;
  -webkit-margin-before: 0.5em;
          margin-block-start: 0.5em;
  -webkit-margin-after: 0.5em;
          margin-block-end: 0.5em;
  -webkit-margin-start: auto;
     -moz-margin-start: auto;
          margin-inline-start: auto;
  -webkit-margin-end: auto;
     -moz-margin-end: auto;
          margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
  max-width: 200px;
  padding: 0;
  border: none;
  /* border-top: medium double #888; */
  border-top: medium double #ededed;
  color: #888;
  text-align: center;
  overflow: visible;
  z-index: 0;
  position: relative; }
  .prettyHr:after {
    content: '\A7';
    display: inline-block;
    position: absolute;
    color: #ededed;
    top: -2.2rem;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(25deg);
       -moz-transform: translateX(-50%) rotate(25deg);
        -ms-transform: translateX(-50%) rotate(25deg);
         -o-transform: translateX(-50%) rotate(25deg);
            transform: translateX(-50%) rotate(25deg);
    font-size: 2rem;
    padding: 0 0.2rem;
    text-align: center;
    z-index: 1; }

.info-box {
  margin-top: 30px;
  width: 100%;
  background: url("/images/menu-outer-bg3.jpg");
  background-repeat: repeat;
  padding: 10px;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media (max-width: 1024px) and (orientation: portrait) {
    .info-box {
      margin-top: 20px;
      margin-bottom: 20px; } }
  @media (max-width: 767px) {
    .info-box {
      margin-top: 0;
      margin-bottom: 0; } }
  .info-box h2 {
    text-align: center;
    line-height: 1.3;
    color: #ededed;
    background: #232d3e;
    display: inline-block;
    padding: 10px 15px;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    font-family: "Amatic SC", cursive;
    font-size: 26px;
    letter-spacing: 1px;
    margin-top: 10px; }
  .info-box .info-items {
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr; }
    @media (max-width: 1023px) {
      .info-box .info-items {
        grid-template-columns: 1fr; } }
    .info-box .info-items .store-hours {
      border-right: 2px solid #b2b2b2; }
      @media (max-width: 1023px) {
        .info-box .info-items .store-hours {
          border: 0; } }
    .info-box .info-items .store-hours,
    .info-box .info-items .store-info {
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .info-box .info-items .store-hours h3,
      .info-box .info-items .store-info h3 {
        text-align: center;
        color: #393939;
        text-transform: uppercase;
        font-family: "Playfair Display", serif;
        font-size: 30px;
        line-height: 1;
        margin-top: 10px; }
      .info-box .info-items .store-hours .store-hours-items,
      .info-box .info-items .store-hours .store-info-items,
      .info-box .info-items .store-info .store-hours-items,
      .info-box .info-items .store-info .store-info-items {
        width: 100%; }
        .info-box .info-items .store-hours .store-hours-items .store-hours-item .item-1,
        .info-box .info-items .store-hours .store-info-items .store-hours-item .item-1,
        .info-box .info-items .store-info .store-hours-items .store-hours-item .item-1,
        .info-box .info-items .store-info .store-info-items .store-hours-item .item-1 {
          font-family: "Open Sans", sans-serif; }
        .info-box .info-items .store-hours .store-hours-items .store-hours-item .item-2,
        .info-box .info-items .store-hours .store-info-items .store-hours-item .item-2,
        .info-box .info-items .store-info .store-hours-items .store-hours-item .item-2,
        .info-box .info-items .store-info .store-info-items .store-hours-item .item-2 {
          font-family: "Playfair Display", serif; }
        .info-box .info-items .store-hours .store-hours-items .store-hours-item,
        .info-box .info-items .store-hours .store-hours-items .store-info-item,
        .info-box .info-items .store-hours .store-info-items .store-hours-item,
        .info-box .info-items .store-hours .store-info-items .store-info-item,
        .info-box .info-items .store-info .store-hours-items .store-hours-item,
        .info-box .info-items .store-info .store-hours-items .store-info-item,
        .info-box .info-items .store-info .store-info-items .store-hours-item,
        .info-box .info-items .store-info .store-info-items .store-info-item {
          width: 100%;
          display: -webkit-flex;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          margin-top: 10px; }
          .info-box .info-items .store-hours .store-hours-items .store-hours-item .item,
          .info-box .info-items .store-hours .store-hours-items .store-info-item .item,
          .info-box .info-items .store-hours .store-info-items .store-hours-item .item,
          .info-box .info-items .store-hours .store-info-items .store-info-item .item,
          .info-box .info-items .store-info .store-hours-items .store-hours-item .item,
          .info-box .info-items .store-info .store-hours-items .store-info-item .item,
          .info-box .info-items .store-info .store-info-items .store-hours-item .item,
          .info-box .info-items .store-info .store-info-items .store-info-item .item {
            -webkit-flex-basis: 50%;
                -ms-flex-preferred-size: 50%;
                    flex-basis: 50%;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 18px; }
            @media (max-width: 576px) {
              .info-box .info-items .store-hours .store-hours-items .store-hours-item .item,
              .info-box .info-items .store-hours .store-hours-items .store-info-item .item,
              .info-box .info-items .store-hours .store-info-items .store-hours-item .item,
              .info-box .info-items .store-hours .store-info-items .store-info-item .item,
              .info-box .info-items .store-info .store-hours-items .store-hours-item .item,
              .info-box .info-items .store-info .store-hours-items .store-info-item .item,
              .info-box .info-items .store-info .store-info-items .store-hours-item .item,
              .info-box .info-items .store-info .store-info-items .store-info-item .item {
                font-size: 14px; } }
            @media (max-width: 359px) {
              .info-box .info-items .store-hours .store-hours-items .store-hours-item .item,
              .info-box .info-items .store-hours .store-hours-items .store-info-item .item,
              .info-box .info-items .store-hours .store-info-items .store-hours-item .item,
              .info-box .info-items .store-hours .store-info-items .store-info-item .item,
              .info-box .info-items .store-info .store-hours-items .store-hours-item .item,
              .info-box .info-items .store-info .store-hours-items .store-info-item .item,
              .info-box .info-items .store-info .store-info-items .store-hours-item .item,
              .info-box .info-items .store-info .store-info-items .store-info-item .item {
                font-size: 12px; } }
          .info-box .info-items .store-hours .store-hours-items .store-hours-item .item-1,
          .info-box .info-items .store-hours .store-hours-items .store-info-item .item-1,
          .info-box .info-items .store-hours .store-info-items .store-hours-item .item-1,
          .info-box .info-items .store-hours .store-info-items .store-info-item .item-1,
          .info-box .info-items .store-info .store-hours-items .store-hours-item .item-1,
          .info-box .info-items .store-info .store-hours-items .store-info-item .item-1,
          .info-box .info-items .store-info .store-info-items .store-hours-item .item-1,
          .info-box .info-items .store-info .store-info-items .store-info-item .item-1 {
            text-align: right;
            padding-right: 20px;
            color: #393939; }
            @media (max-width: 576px) {
              .info-box .info-items .store-hours .store-hours-items .store-hours-item .item-1,
              .info-box .info-items .store-hours .store-hours-items .store-info-item .item-1,
              .info-box .info-items .store-hours .store-info-items .store-hours-item .item-1,
              .info-box .info-items .store-hours .store-info-items .store-info-item .item-1,
              .info-box .info-items .store-info .store-hours-items .store-hours-item .item-1,
              .info-box .info-items .store-info .store-hours-items .store-info-item .item-1,
              .info-box .info-items .store-info .store-info-items .store-hours-item .item-1,
              .info-box .info-items .store-info .store-info-items .store-info-item .item-1 {
                padding-right: 10px; } }
          .info-box .info-items .store-hours .store-hours-items .store-hours-item .item-2,
          .info-box .info-items .store-hours .store-hours-items .store-info-item .item-2,
          .info-box .info-items .store-hours .store-info-items .store-hours-item .item-2,
          .info-box .info-items .store-hours .store-info-items .store-info-item .item-2,
          .info-box .info-items .store-info .store-hours-items .store-hours-item .item-2,
          .info-box .info-items .store-info .store-hours-items .store-info-item .item-2,
          .info-box .info-items .store-info .store-info-items .store-hours-item .item-2,
          .info-box .info-items .store-info .store-info-items .store-info-item .item-2 {
            text-align: left;
            padding-left: 20px; }
            @media (max-width: 576px) {
              .info-box .info-items .store-hours .store-hours-items .store-hours-item .item-2,
              .info-box .info-items .store-hours .store-hours-items .store-info-item .item-2,
              .info-box .info-items .store-hours .store-info-items .store-hours-item .item-2,
              .info-box .info-items .store-hours .store-info-items .store-info-item .item-2,
              .info-box .info-items .store-info .store-hours-items .store-hours-item .item-2,
              .info-box .info-items .store-info .store-hours-items .store-info-item .item-2,
              .info-box .info-items .store-info .store-info-items .store-hours-item .item-2,
              .info-box .info-items .store-info .store-info-items .store-info-item .item-2 {
                padding-left: 10px; } }
          .info-box .info-items .store-hours .store-hours-items .store-hours-item .green,
          .info-box .info-items .store-hours .store-hours-items .store-info-item .green,
          .info-box .info-items .store-hours .store-info-items .store-hours-item .green,
          .info-box .info-items .store-hours .store-info-items .store-info-item .green,
          .info-box .info-items .store-info .store-hours-items .store-hours-item .green,
          .info-box .info-items .store-info .store-hours-items .store-info-item .green,
          .info-box .info-items .store-info .store-info-items .store-hours-item .green,
          .info-box .info-items .store-info .store-info-items .store-info-item .green {
            color: #008b00; }
          .info-box .info-items .store-hours .store-hours-items .store-hours-item .darkRed,
          .info-box .info-items .store-hours .store-hours-items .store-info-item .darkRed,
          .info-box .info-items .store-hours .store-info-items .store-hours-item .darkRed,
          .info-box .info-items .store-hours .store-info-items .store-info-item .darkRed,
          .info-box .info-items .store-info .store-hours-items .store-hours-item .darkRed,
          .info-box .info-items .store-info .store-hours-items .store-info-item .darkRed,
          .info-box .info-items .store-info .store-info-items .store-hours-item .darkRed,
          .info-box .info-items .store-info .store-info-items .store-info-item .darkRed {
            color: #8b0000; }
    .info-box .info-items .store-info h3 {
      margin-top: 20px; }
    .info-box .info-items .store-info .store-info-items {
      width: 80% !important;
      margin: 0 auto; }
      .info-box .info-items .store-info .store-info-items .store-info-item {
        margin-top: 20px !important; }
        .info-box .info-items .store-info .store-info-items .store-info-item .item-1 {
          -webkit-flex-basis: 20% !important;
              -ms-flex-preferred-size: 20% !important;
                  flex-basis: 20% !important; }
        .info-box .info-items .store-info .store-info-items .store-info-item .item-2 {
          -webkit-flex-basis: 80% !important;
              -ms-flex-preferred-size: 80% !important;
                  flex-basis: 80% !important; }
        .info-box .info-items .store-info .store-info-items .store-info-item .fa-map-marked-alt,
        .info-box .info-items .store-info .store-info-items .store-info-item .fa-phone {
          color: #ffa500;
          font-size: 30px; }
        .info-box .info-items .store-info .store-info-items .store-info-item p {
          font-family: "Open Sans", sans-serif;
          font-size: 18px;
          color: #393939;
          line-height: 1.4;
          display: -webkit-flex;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-align-items: flex-start;
             -moz-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start;
          font-weight: bold; }
    .info-box .info-items .store-logo {
      margin-top: 10px;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .info-box .info-items .store-logo img {
        width: 100%;
        max-width: 240px;
        -webkit-backface-visibility: hidden;
           -moz-backface-visibility: hidden;
                backface-visibility: hidden; }
    @media (max-width: 1023px) {
      .info-box .info-items .store-hours {
        -webkit-order: 1;
           -moz-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1; }
      .info-box .info-items .store-info {
        -webkit-order: 2;
           -moz-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2; } }

.map-box {
  background: #cbcbcb;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 16px 0;
  border: 1px solid #232d3e; }
  .map-box .buttonBox {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 5px;
    padding-right: 3px;
    background: transparent; }
    .map-box .buttonBox a {
      background: #232d3e;
      padding: 16px;
      color: #ededed;
      margin-right: 2px;
      border: 1px solid #ededed;
      cursor: pointer;
      text-align: center;
      font-size: 20px;
      line-height: 1.5; }
  @media (max-width: 1024px) and (orientation: portrait) {
    .map-box {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0; } }
  @media (max-width: 767px) and (orientation: portrait) {
    .map-box {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0; } }
  .map-box .store-map {
    width: 100%;
    height: 500px; }
    @media (max-width: 767px) and (orientation: portrait) {
      .map-box .store-map {
        height: 400px; } }
    @media (max-height: 414px) and (orientation: landscape) {
      .map-box .store-map {
        height: 300px; } }
    @media (max-height: 380px) and (orientation: landscape) {
      .map-box .store-map {
        height: 280px; } }

.toTop {
  z-index: 3;
  position: fixed;
  right: -10%;
  bottom: -10%;
  -webkit-transition: all 500ms;
  -o-transition: all 500ms;
  -moz-transition: all 500ms;
  transition: all 500ms;
  width: 44px;
  height: 44px;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  background: #8b0000;
  color: #fff;
  opacity: 0.5;
  border: 1px solid #fff;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  outline: 0; }
  .toTop:hover {
    opacity: 1; }
  .toTop:active {
    -webkit-transform: scale(0.8);
       -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
         -o-transform: scale(0.8);
            transform: scale(0.8); }
  .toTop .fa-chevron-up {
    color: #fff; }
  .toTop.active {
    right: 2%;
    bottom: 2%; }

.daily-specials {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (min-width: 1024px) {
    .daily-specials {
      margin-top: 40px; } }
  .daily-specials .headingBox {
    background: #8b0000;
    padding: 5px 40px;
    -webkit-border-radius: 60px;
       -moz-border-radius: 60px;
            border-radius: 60px;
    margin-bottom: 10px; }
    .daily-specials .headingBox h1 {
      color: #fff;
      line-height: 1;
      font-family: "Kalam", cursive;
      font-size: 24px; }
      @media (min-width: 399px) {
        .daily-specials .headingBox h1 {
          font-size: 30px; } }
      @media (min-width: 1024px) {
        .daily-specials .headingBox h1 {
          font-size: 44px; } }
  .daily-specials .titleDesc {
    text-align: center;
    color: #35455f;
    font-weight: bold;
    font-style: italic;
    line-height: 1.2;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px; }
  .daily-specials .seoulContainer {
    overflow: hidden;
    padding: 0 5px;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .daily-specials .seoulContainer .left-outer {
      position: relative;
      width: 100%;
      height: 100%; }
      .daily-specials .seoulContainer .left-outer .fade {
        opacity: 0;
        z-index: 1; }
      .daily-specials .seoulContainer .left-outer .fade-enter {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        opacity: 1;
        -webkit-transition: opacity 300ms linear;
        -o-transition: opacity 300ms linear;
        -moz-transition: opacity 300ms linear;
        transition: opacity 300ms linear; }
      .daily-specials .seoulContainer .left-outer .fade-enter.fade-enter-active {
        opacity: 1; }
      .daily-specials .seoulContainer .left-outer .fade-exit {
        opacity: 1; }
      .daily-specials .seoulContainer .left-outer .fade-exit.fade-exit-active {
        opacity: 0;
        -webkit-transition: opacity 150ms linear;
        -o-transition: opacity 150ms linear;
        -moz-transition: opacity 150ms linear;
        transition: opacity 150ms linear; }
      .daily-specials .seoulContainer .left-outer .fade-enter-done {
        opacity: 1; }
    .daily-specials .seoulContainer .left {
      position: relative;
      overflow: hidden;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      height: 320px;
      width: 100%; }
      .daily-specials .seoulContainer .left .content {
        left: 0;
        top: 0;
        right: 0;
        position: absolute;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        width: 100%;
        height: 100%;
        padding-left: 10px;
        padding-right: 10px; }
        .daily-specials .seoulContainer .left .content p {
          -webkit-flex: 1 0 auto;
             -moz-box-flex: 1;
              -ms-flex: 1 0 auto;
                  flex: 1 0 auto;
          display: -webkit-flex;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-align-items: center;
             -moz-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          color: #fff;
          text-align: center;
          font-size: 20px;
          text-shadow: 0 0 4px rgba(0, 0, 0, 0.72), 0 0 14px rgba(0, 0, 0, 0.45); }
        .daily-specials .seoulContainer .left .content .left-day {
          -webkit-transform: translateY(-100%);
             -moz-transform: translateY(-100%);
              -ms-transform: translateY(-100%);
               -o-transform: translateY(-100%);
                  transform: translateY(-100%);
          -webkit-transition: -webkit-transform 500ms;
          transition: -webkit-transform 500ms;
          -o-transition: -o-transform 500ms;
          -moz-transition: transform 500ms, -moz-transform 500ms;
          transition: transform 500ms;
          transition: transform 500ms, -webkit-transform 500ms, -moz-transform 500ms, -o-transform 500ms;
          font-style: italic; }
        .daily-specials .seoulContainer .left .content .left-name {
          font-family: "Amatic SC", cursive;
          font-weight: 700;
          font-size: 44px;
          opacity: 0;
          -webkit-transition: opacity 1000ms;
          -o-transition: opacity 1000ms;
          -moz-transition: opacity 1000ms;
          transition: opacity 1000ms;
          display: -webkit-flex;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-justify-content: center;
             -moz-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          width: 100%;
          position: relative; }
          .daily-specials .seoulContainer .left .content .left-name .left-desc2 {
            position: absolute;
            font-family: "Roboto Slab", serif;
            left: 50%;
            -webkit-transform: translateX(-50%);
               -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                 -o-transform: translateX(-50%);
                    transform: translateX(-50%);
            top: 90%;
            font-size: 12px;
            width: 100%; }
          .daily-specials .seoulContainer .left .content .left-name .priceBox {
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
               -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                 -o-transform: translateX(-50%);
                    transform: translateX(-50%);
            top: 65%;
            font-size: 16px;
            width: 100%; }
            .daily-specials .seoulContainer .left .content .left-name .priceBox .regularPrice {
              font-family: "Roboto Slab", serif;
              text-decoration: line-through;
              font-size: 18px; }
            .daily-specials .seoulContainer .left .content .left-name .priceBox .price {
              margin-left: 5px;
              font-family: "Playfair Display", serif;
              color: #4da6ff;
              font-size: 26px; }
        .daily-specials .seoulContainer .left .content .left-desc {
          -webkit-transform: translateY(100%);
             -moz-transform: translateY(100%);
              -ms-transform: translateY(100%);
               -o-transform: translateY(100%);
                  transform: translateY(100%);
          -webkit-transition: -webkit-transform 500ms;
          transition: -webkit-transform 500ms;
          -o-transition: -o-transform 500ms;
          -moz-transition: transform 500ms, -moz-transform 500ms;
          transition: transform 500ms;
          transition: transform 500ms, -webkit-transform 500ms, -moz-transform 500ms, -o-transform 500ms;
          font-style: italic;
          line-height: 1; }
      .daily-specials .seoulContainer .left.fade-enter-done .left-day,
      .daily-specials .seoulContainer .left.fade-enter-done .left-desc {
        -webkit-transform: translateY(0);
           -moz-transform: translateY(0);
            -ms-transform: translateY(0);
             -o-transform: translateY(0);
                transform: translateY(0); }
      .daily-specials .seoulContainer .left.fade-enter-done .left-name {
        opacity: 1;
        font-size: 44px; }
    .daily-specials .seoulContainer .right {
      width: 100%;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-align-items: flex-end;
         -moz-box-align: end;
          -ms-flex-align: end;
              align-items: flex-end; }
      .daily-specials .seoulContainer .right .right-inner {
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        padding: 5px;
        width: 100%; }
        .daily-specials .seoulContainer .right .right-inner .right-item {
          width: 33.33%;
          padding: 4px;
          -webkit-backface-visibility: hidden;
             -moz-backface-visibility: hidden;
                  backface-visibility: hidden; }
          .daily-specials .seoulContainer .right .right-inner .right-item.active .right-item-inner:before {
            opacity: 0; }
          .daily-specials .seoulContainer .right .right-inner .right-item.active .right-item-inner .content {
            background: rgba(0, 0, 0, 0.3); }
          .daily-specials .seoulContainer .right .right-inner .right-item .right-item-inner {
            width: 100%;
            height: 60px;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            -webkit-backface-visibility: hidden;
               -moz-backface-visibility: hidden;
                    backface-visibility: hidden;
            -webkit-border-radius: 5px;
               -moz-border-radius: 5px;
                    border-radius: 5px;
            -webkit-box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.2);
               -moz-box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.2);
                    box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.2); }
            .daily-specials .seoulContainer .right .right-inner .right-item .right-item-inner:before {
              position: absolute;
              -webkit-backface-visibility: hidden;
                 -moz-backface-visibility: hidden;
                      backface-visibility: hidden;
              content: "";
              top: 0;
              left: 0;
              right: 0;
              height: 100%;
              background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.3)));
              background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
              background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
              background: -o-linear-gradient(left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
              background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
              -webkit-transition: opacity 500ms ease-in-out;
              -o-transition: opacity 500ms ease-in-out;
              -moz-transition: opacity 500ms ease-in-out;
              transition: opacity 500ms ease-in-out;
              opacity: 1; }
            .daily-specials .seoulContainer .right .right-inner .right-item .right-item-inner .content {
              color: #fff;
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                 -moz-transform: translate(-50%, -50%);
                  -ms-transform: translate(-50%, -50%);
                   -o-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
              padding: 2px 6px;
              -webkit-border-radius: 3px;
                 -moz-border-radius: 3px;
                      border-radius: 3px;
              text-shadow: 0 0 4px rgba(0, 0, 0, 0.72), 0 0 14px rgba(0, 0, 0, 0.45);
              -webkit-transition: background 500ms ease-in-out;
              -o-transition: background 500ms ease-in-out;
              -moz-transition: background 500ms ease-in-out;
              transition: background 500ms ease-in-out; }
              .daily-specials .seoulContainer .right .right-inner .right-item .right-item-inner .content h5 {
                font-style: italic; }
              .daily-specials .seoulContainer .right .right-inner .right-item .right-item-inner .content .everyday {
                color: #99ccff; }
    @media (min-width: 1024px) {
      .daily-specials .seoulContainer {
        height: 100%;
        height: 500px;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        margin-top: 20px; }
        .daily-specials .seoulContainer .left-outer {
          -webkit-flex-basis: 50%;
              -ms-flex-preferred-size: 50%;
                  flex-basis: 50%; }
        .daily-specials .seoulContainer .left,
        .daily-specials .seoulContainer .right {
          -webkit-flex-basis: 50%;
              -ms-flex-preferred-size: 50%;
                  flex-basis: 50%;
          height: 100%; }
        .daily-specials .seoulContainer .left {
          -webkit-border-radius: 40px;
             -moz-border-radius: 40px;
                  border-radius: 40px;
          -webkit-order: 1;
             -moz-box-ordinal-group: 2;
              -ms-flex-order: 1;
                  order: 1;
          height: 500px; }
        .daily-specials .seoulContainer .right {
          -webkit-order: 2;
             -moz-box-ordinal-group: 3;
              -ms-flex-order: 2;
                  order: 2;
          height: auto;
          -webkit-justify-content: center;
             -moz-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-align-items: center;
             -moz-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          .daily-specials .seoulContainer .right .right-inner {
            padding: 10px; }
            .daily-specials .seoulContainer .right .right-inner .right-item {
              padding: 10px; }
              .daily-specials .seoulContainer .right .right-inner .right-item.active .right-item-inner:before {
                opacity: 0; }
              .daily-specials .seoulContainer .right .right-inner .right-item.active .right-item-inner .content {
                background: rgba(0, 0, 0, 0.3); }
              .daily-specials .seoulContainer .right .right-inner .right-item .right-item-inner {
                -webkit-border-radius: 20px;
                   -moz-border-radius: 20px;
                        border-radius: 20px;
                height: 200px; }
                .daily-specials .seoulContainer .right .right-inner .right-item .right-item-inner:before {
                  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.3)));
                  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
                  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
                  background: -o-linear-gradient(left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
                  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)); } }

.testimonials {
  width: 100%;
  margin-bottom: 10px; }
  .testimonials .seoulContainer {
    padding: 20px 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.3))), url("/images/review_background.jpg");
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url("/images/review_background.jpg");
    background: -moz-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url("/images/review_background.jpg");
    background: -o-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url("/images/review_background.jpg");
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url("/images/review_background.jpg");
    background-position: center;
    -moz-background-size: cover;
      -o-background-size: cover;
         background-size: cover;
    background-repeat: no-repeat; }
    .testimonials .seoulContainer h1 {
      font-family: 'Kalam', cursive;
      font-weight: bold;
      text-align: center;
      color: #fff;
      line-height: 1; }
    .testimonials .seoulContainer .button-box {
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .testimonials .seoulContainer .button-box button:nth-of-type(1) {
        margin-right: 2rem; }
      .testimonials .seoulContainer .button-box button {
        font-size: 16px;
        padding: 10px 30px;
        outline: none;
        border: none;
        -webkit-border-radius: 5px;
           -moz-border-radius: 5px;
                border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
        background: #8b0000;
        color: #fff;
        -webkit-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        transition: all 300ms ease; }
      .testimonials .seoulContainer .button-box button:active {
        -webkit-transform: scale(0.9);
           -moz-transform: scale(0.9);
            -ms-transform: scale(0.9);
             -o-transform: scale(0.9);
                transform: scale(0.9); }
      .testimonials .seoulContainer .button-box button.disabled {
        visibility: hidden; }
      .testimonials .seoulContainer .button-box button:disabled {
        background: #ffbebe;
        color: #ddd;
        cursor: not-allowed;
        -webkit-transform: scale(1);
           -moz-transform: scale(1);
            -ms-transform: scale(1);
             -o-transform: scale(1);
                transform: scale(1);
        visibility: hidden; }
    .testimonials .seoulContainer .m-cards-slider {
      margin-top: 1rem;
      position: relative;
      padding: 0;
      overflow: hidden;
      margin: 0 auto; }
      .testimonials .seoulContainer .m-cards-slider .m-cards-slider-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out; }
        .testimonials .seoulContainer .m-cards-slider .m-cards-slider-wrapper .t-card {
          padding: 1.5rem;
          text-align: center;
          display: -webkit-flex;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-direction: column;
             -moz-box-orient: vertical;
             -moz-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          color: #fff; }
          .testimonials .seoulContainer .m-cards-slider .m-cards-slider-wrapper .t-card .feedback p {
            font-family: 'Lato', sans-serif;
            line-height: 34px;
            word-spacing: 4px; }
          .testimonials .seoulContainer .m-cards-slider .m-cards-slider-wrapper .t-card .feedback .fa-quote-left {
            font-size: 24px;
            margin-right: 0.5rem; }
          .testimonials .seoulContainer .m-cards-slider .m-cards-slider-wrapper .t-card .feedback .fa-quote-right {
            font-size: 24px;
            margin-left: 0.5rem; }
          .testimonials .seoulContainer .m-cards-slider .m-cards-slider-wrapper .t-card h5 {
            font-size: 18px; }
  .testimonials .more-reviews {
    background: #e1e1e1;
    padding: 0;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  @media (max-width: 399px) {
    .testimonials .seoulContainer h1 {
      margin-top: 0.5rem;
      font-size: 28px; }
    .testimonials .seoulContainer .m-cards-slider {
      width: 280px !important;
      height: 320px !important; }
      .testimonials .seoulContainer .m-cards-slider .t-card {
        min-width: 280px !important;
        width: 280px !important;
        padding: 0.5rem !important; }
        .testimonials .seoulContainer .m-cards-slider .t-card .feedback p {
          line-height: 24px !important; }
    .testimonials .seoulContainer .button-box {
      margin-bottom: 0.5rem; } }
  @media (min-width: 400px) {
    .testimonials .seoulContainer h1 {
      margin-top: 1rem;
      font-size: 30px; }
    .testimonials .seoulContainer .m-cards-slider {
      width: 380px !important;
      height: 300px !important; }
      .testimonials .seoulContainer .m-cards-slider .t-card {
        min-width: 380px !important;
        width: 380px !important;
        padding: 1rem !important; }
        .testimonials .seoulContainer .m-cards-slider .t-card .feedback p {
          line-height: 30px !important; }
    .testimonials .seoulContainer .button-box {
      margin-bottom: 1rem; } }
  @media (min-width: 576px) {
    .testimonials .seoulContainer .m-cards-slider {
      width: 400px !important;
      height: 300px !important; }
      .testimonials .seoulContainer .m-cards-slider .t-card {
        min-width: 400px !important;
        width: 400px !important; } }
  @media (min-width: 768px) {
    .testimonials {
      margin-top: 20px;
      margin-bottom: 20px; }
      .testimonials .seoulContainer h1 {
        font-size: 56px; }
      .testimonials .seoulContainer .m-cards-slider {
        width: 500px !important;
        height: 260px !important; }
        .testimonials .seoulContainer .m-cards-slider .t-card {
          min-width: 500px !important;
          width: 500px !important; } }
  @media (min-width: 992px) {
    .testimonials .seoulContainer .m-cards-slider {
      width: 600px !important;
      height: 260px !important; }
      .testimonials .seoulContainer .m-cards-slider .t-card {
        min-width: 600px !important;
        width: 600px !important; } }
  @media (min-width: 1460px) {
    .testimonials {
      margin-top: 40px;
      margin-bottom: 40px; }
      .testimonials .seoulContainer h1 {
        margin-top: 2rem; }
      .testimonials .seoulContainer .m-cards-slider {
        width: 800px !important;
        height: 240px !important; }
        .testimonials .seoulContainer .m-cards-slider .t-card {
          min-width: 800px !important;
          width: 800px !important; }
      .testimonials .seoulContainer .button-box {
        margin-bottom: 2rem; } }
  .testimonials .menu-line-box {
    width: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 1rem; }
    .testimonials .menu-line-box img {
      height: 25px;
      width: auto; }

.page-not-found {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px; }
  .page-not-found h1 {
    text-align: center;
    color: #35455f; }
  .page-not-found a {
    background: #f3a81d;
    padding: 10px 20px;
    -webkit-transform: skewX(-20deg);
       -moz-transform: skewX(-20deg);
        -ms-transform: skewX(-20deg);
         -o-transform: skewX(-20deg);
            transform: skewX(-20deg);
    color: #8b0000;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px; }
    .page-not-found a div {
      -webkit-transform: skewX(20deg);
         -moz-transform: skewX(20deg);
          -ms-transform: skewX(20deg);
           -o-transform: skewX(20deg);
              transform: skewX(20deg); }
      .page-not-found a div .fa-chevron-left {
        margin-right: 10px; }

.landing-catering {
  margin-top: 40px;
  padding-bottom: 40px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.8)), color-stop(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.8))), url("/images/landing-catering-background.jpg");
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url("/images/landing-catering-background.jpg");
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url("/images/landing-catering-background.jpg");
  background: -o-linear-gradient(left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url("/images/landing-catering-background.jpg");
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url("/images/landing-catering-background.jpg");
  background-repeat: no-repeat;
  -moz-background-size: cover;
    -o-background-size: cover;
       background-size: cover;
  background-position: center;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #ededed; }
  .landing-catering .title {
    margin-top: 40px;
    font-family: "Kalam", cursive;
    font-size: 30px;
    line-height: 1;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.72), 0 0 14px rgba(0, 0, 0, 0.45); }
    @media (min-width: 399px) {
      .landing-catering .title {
        font-size: 40px; } }
    @media (min-width: 1024px) {
      .landing-catering .title {
        font-size: 56px; } }
  .landing-catering .subTitle {
    text-align: center;
    line-height: 1.2;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.72), 0 0 14px rgba(0, 0, 0, 0.45); }
  .landing-catering .expand-button-box {
    margin-top: 10px; }
    .landing-catering .expand-button-box .button {
      padding: 5px 10px; }
    .landing-catering .expand-button-box .expandAllButton {
      background: #008b00;
      margin-right: 10px; }
    .landing-catering .expand-button-box .shirinkAllButton {
      background: #e87c03; }
  .landing-catering .contentBox {
    width: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-evenly;
       -moz-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly; }
    .landing-catering .contentBox .itemName {
      font-size: 24px;
      padding: 5 10px;
      text-shadow: 0 0 4px rgba(0, 0, 0, 0.72), 0 0 14px rgba(0, 0, 0, 0.45); }
      .landing-catering .contentBox .itemName:not(:nth-of-type(2)) {
        margin-top: 10px;
        text-align: center; }
    .landing-catering .contentBox .leftBox {
      padding: 20px; }
      .landing-catering .contentBox .leftBox ul {
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
    .landing-catering .contentBox .middleBox {
      padding: 20px; }
      .landing-catering .contentBox .middleBox ul {
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
    .landing-catering .contentBox .rightBox {
      padding: 20px; }
      .landing-catering .contentBox .rightBox ul {
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
    .landing-catering .contentBox .panel {
      background: transparent;
      max-height: 0;
      overflow: hidden;
      -webkit-transition: max-height 200ms ease-in-out;
      -o-transition: max-height 200ms ease-in-out;
      -moz-transition: max-height 200ms ease-in-out;
      transition: max-height 200ms ease-in-out; }
      .landing-catering .contentBox .panel li {
        font-size: 16px;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.72), 0 0 14px rgba(0, 0, 0, 0.45); }
    .landing-catering .contentBox .titleButton {
      color: #ededed;
      -webkit-transition: color 200ms ease-in-out;
      -o-transition: color 200ms ease-in-out;
      -moz-transition: color 200ms ease-in-out;
      transition: color 200ms ease-in-out; }
      .landing-catering .contentBox .titleButton.active .itemName, .landing-catering .contentBox .titleButton:hover .itemName {
        color: #ffe866; }
  .landing-catering .expand-button-box button {
    color: #ededed; }
  .landing-catering ul li .panel-ul {
    list-style-type: circle !important; }

